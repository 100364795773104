import { Response } from 'aws-sdk/lib/response';
import { AWSError } from 'aws-sdk/lib/error';
import { Progress, Request } from 'aws-sdk/lib/request';
import { PutObjectOutput } from 'aws-sdk/clients/s3';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'lib/hooks';
import { trackEvent } from 'lib/features/events/thunks';
import { postUploadFileS3 } from 'connectors/server';
import { ErrorWithOriginal } from 'common/utils';

export interface UploadFileByS3PropsOptions {
    onHttpUploadProgress?: (progress: Progress) => void;
    onComplete?: (response: Response<PutObjectOutput, AWSError>) => void;
    onError?: (err: AWSError) => void;
}

export interface UploadFileByS3Props {
    ciphertext?: string;
    fileName: string;
}

export type UploadFileByS3Result = Request<PutObjectOutput, AWSError>;

export interface UploadFileProps {
    ciphertext: string;
    fileName: string;
}

export interface UseFileUploaderResult {
    uploadFile: (props: UploadFileProps) => Promise<void>;
    uploading: boolean;
}

export const useFileUploader = (): UseFileUploaderResult => {
  const dispatch = useAppDispatch();
  const [uploading, setUploading] = useState(false);
  const uploadFile = useCallback(async (props: UploadFileProps): Promise<void> => {
    setUploading(true);
    try {
      const response = await postUploadFileS3(props);
      if (!response?.ok) {
        if (response.status === 413) {
          throw new Error(await response.text().catch(() => 'Request Entity Too Large'));
        }
        throw new Error((await response.json().catch(() => null))?.error || response?.statusText);
      }
      dispatch(trackEvent({ eventType: 'order_creation_file_upload', property: { result: 'success' } }));
    } catch (e) {
      dispatch(trackEvent({
        eventType: 'order_creation_file_upload',
        property: { result: 'error', error: (e as Error)?.message, errorStack: (e as Error)?.stack },
      }));
      throw new ErrorWithOriginal(e, 'File upload error');
    } finally {
      setUploading(false);
    }
  }, [dispatch]);
  return {
    uploadFile,
    uploading,
  };
};
