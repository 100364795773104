import {
  Encryption,
  ResourceType,
  StorageType,
  Resource,
} from '@super-protocol/dto-js';
import { useCallback, useState } from 'react';
import getConfig from 'config';

export interface GenerateByOfferProps {
    offerId: string;
    encryption: Encryption;
    filepath: string;
    inputOffers: string[];
}

export interface UsePublishTeeResult {
    generateByOffer: (props: GenerateByOfferProps) => Promise<string | undefined>;
    generating: boolean;
}

export const useGenerateTII = (): UsePublishTeeResult => {
  const [generating, setGenerating] = useState(false);
  const generateByOffer = useCallback(async (props: GenerateByOfferProps): Promise<string | undefined> => {
    const { NEXT_PUBLIC_S3_ARGS_BUCKET_READ_ACCESS, NEXT_PUBLIC_PCCS_URL } = getConfig();
    if (!NEXT_PUBLIC_S3_ARGS_BUCKET_READ_ACCESS) {
      throw new Error('Requires s3 read token');
    }
    setGenerating(true);
    const { TIIGenerator } = (await import('@super-protocol/sdk-js'));
    try {
      const {
        offerId,
        encryption,
        filepath,
        inputOffers,
      } = props;
      const { solutionHashes, linkage } = await TIIGenerator.getOffersHashesAndLinkage(inputOffers);
      return TIIGenerator.generateByOffer({
        offerId,
        solutionHashes,
        linkageString: linkage,
        resource: {
          type: ResourceType.StorageProvider,
          storageType: StorageType.StorJ,
          filepath,
          credentials: {
            bucket: 'inputs',
            prefix: '',
            token: NEXT_PUBLIC_S3_ARGS_BUCKET_READ_ACCESS,
          },
        } as Resource,
        args: undefined,
        encryption,
        sgxApiUrl: NEXT_PUBLIC_PCCS_URL,
      });
    } finally {
      setGenerating(false);
    }
  }, []);
  return {
    generating,
    generateByOffer,
  };
};
