import { useCallback, useState } from 'react';
import { CryptoAlgorithm, Encoding } from '@super-protocol/dto-js/build/enum';
import { Encryption, Cipher } from '@super-protocol/dto-js';
import { generateRandomKeys } from 'common/utils/crypto';
import { getBinaryStringFromFile } from 'common/utils';

export interface UseEncryptFileResultEncryptFileResult {
    encryption: Encryption;
    key: string;
}

export interface UseEncryptFileResult {
    encryptFile: (file: File) => Promise<UseEncryptFileResultEncryptFileResult>;
    encrypting: boolean;
}

export const useEncryptFile = (): UseEncryptFileResult => {
  const [encrypting, setEncrypting] = useState(false);
  const encryptFile = useCallback(async (file: File): Promise<UseEncryptFileResultEncryptFileResult> => {
    setEncrypting(true);
    const { privateKeyBase64 } = await generateRandomKeys();
    const binaryString = await getBinaryStringFromFile(file);
    try {
      const encryption = await (await import('@super-protocol/sdk-js')).Crypto.encrypt(
        binaryString,
        {
          algo: CryptoAlgorithm.AES,
          encoding: Encoding.base64,
          key: privateKeyBase64,
          cipher: Cipher.AES_256_GCM,
        },
      );
      return {
        encryption,
        key: privateKeyBase64,
      };
    } finally {
      setEncrypting(false);
    }
  }, []);
  return {
    encryptFile,
    encrypting,
  };
};
