import {
  memo, FC, useCallback, useState, useMemo,
} from 'react';
import { Formik } from 'formik';
import { Box } from 'uikit/Box';
import { Notification } from 'uikit/Notification';
import { CheckboxFormik } from 'uikit/Checkbox/CheckboxFormik';
import { Button } from 'uikit/Buttons/Button';
import { MnemonicGeneratorFormik } from 'uikit/MnemonicGenerator/MnemonicGeneratorFormik';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { CreatePassphraseForm, FieldsCreatePassphraseForm, Steps } from 'lib/features/createOrder/types';
import { createPassphraseFormSelector } from 'lib/features/createOrder/selectors';
import {
  goNextStep as goNextStepAction,
  goBackStep as goBackStepAction,
  updateFormByStep as updateFormByStepAction,
} from 'lib/features/createOrder';
import { generateMnemonic } from 'common/utils/crypto';
import { addSuccessNotification } from 'lib/features/notifications';
import { Modes } from 'uikit/MnemonicGenerator/MnemonicGeneratorUi/types';
import { themeSelector } from 'lib/features/theme';
import { Theme } from 'uikit/common/types';
import { trackEvent } from 'lib/features/events/thunks';
import { Wrap } from '../Wrap';
import { CreatePassphraseProps } from './types';
import classes from './CreatePassphrase.module.scss';
import { getValidationSchema } from './helpers';
import { FormikPersistRedux } from '../FormikPersistRedux';
import { useCheckOrdersLimit } from '../hooks/useCheckOrdersLimit';

export const CreatePassphrase: FC<CreatePassphraseProps> = memo(() => {
  const { loading, check } = useCheckOrdersLimit();
  const dispatch = useAppDispatch();
  const theme = useAppSelector(themeSelector);
  const createPassphraseForm = useAppSelector(createPassphraseFormSelector);
  const [isValidating, setIsValidating] = useState(false);
  const initialValues = useMemo(() => createPassphraseForm, [createPassphraseForm]);
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const onSubmit = useCallback((submitForm: () => Promise<void>) => async () => {
    if (!await check()) return;
    setIsValidating(true);
    submitForm();
  }, [check]);
  const goNextStep = useCallback(() => {
    dispatch(goNextStepAction());
  }, [dispatch]);
  const goBackStep = useCallback(() => {
    dispatch(goBackStepAction());
  }, [dispatch]);
  const onSubmitForm = useCallback(async (props: CreatePassphraseForm) => {
    setIsValidating(true);
    if (props?.phraseMode === Modes.own) {
      dispatch(trackEvent({ eventType: 'order_form_enter_own_passphrase' }));
    }
    goNextStep();
  }, [goNextStep, dispatch]);
  const onGenerate = useCallback((
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => () => {
    setFieldValue(FieldsCreatePassphraseForm.phraseGenerated, generateMnemonic());
  }, []);
  const onCopy = useCallback(() => {
    dispatch(addSuccessNotification('Passphrase copied'));
    dispatch(trackEvent({ eventType: 'order_form_passphrase_copied' }));
  }, [dispatch]);

  return (
    <Formik<CreatePassphraseForm>
      validateOnChange={isValidating}
      validateOnBlur={isValidating}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
    >
      {({
        setFieldValue, values, submitForm,
      }) => {
        return (
          <>
            <FormikPersistRedux<CreatePassphraseForm>
              action={updateFormByStepAction}
              step={Steps.CREATE_PASSPHRASE}
              values={values}
            />
            <Wrap className={classes[theme]}>
              <Box className={classes.content} direction="column">
                <Box className={classes.title}>
                  Each order has its own 24-word passphrase that you will need to get access.
                  You can use your own passphrase or generate a unique one.
                </Box>
                <MnemonicGeneratorFormik
                  namePhraseInput={FieldsCreatePassphraseForm.phraseInput}
                  namePhraseGenerated={FieldsCreatePassphraseForm.phraseGenerated}
                  nameMode={FieldsCreatePassphraseForm.phraseMode}
                  onGenerate={onGenerate(setFieldValue)}
                  theme={theme}
                  onCopy={onCopy}
                />
                {values[FieldsCreatePassphraseForm.phraseMode] === Modes.generate && (
                  <Notification
                    className={classes.notification}
                    variant="warning"
                  >
                    Write down your passphrase or you will lose the order! We do not store your keys and there is no recovery.
                  </Notification>
                )}
              </Box>
              <Box direction="column" className={classes.footer}>
                <Box className={classes.title}>
                  By pressing the “Place Order” button you agree that your order will be created on blockchain.
                  After this step orders may only be cancelled, subject to cancellation conditions.
                </Box>
                <CheckboxFormik
                  classNameWrap={classes.checkbox}
                  name={FieldsCreatePassphraseForm.agreement}
                  label="I understand and I have saved the passphrase."
                  checkTouched={!isValidating}
                  theme={theme}
                />
                <Box justifyContent="space-between">
                  <Button
                    variant={theme === Theme.dark ? 'black' : 'white'}
                    className={classes.btn}
                    theme={theme}
                    onClick={goBackStep}
                  >Back
                  </Button>
                  <Button
                    onClick={onSubmit(submitForm)}
                    theme={theme}
                    className={classes.btn}
                    loading={loading}
                    data-testid="button-place-order"
                  >
                    Place Order
                  </Button>
                </Box>
              </Box>
            </Wrap>
          </>
        );
      }}
    </Formik>
  );
});